import * as React from "react"
// import {Link} from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import ConnectForm, { connectFormModes } from "../../components/shared/forms/connect-form-IDA"

const IdaSignUpPage = ({ location }) => {
    return (
        <>
            <IDALayout
                location={location}
                locationHostName={location.hostname}
                jobCode="PP-AUR-US-1408 (v2.0)"
                jobCodeDate="03/22"
            >
                <Helmet>
                    <html lang="en" />
                    <link rel="canonical" href="https://www.auryxiahcp.com/iron-deficiency-anemia/sign-up" />
                    <body id="ida-hcp-connect" className="ida" />
                </Helmet>

                <Seo
                    title="Latest News Sign-up"
                    description="Talk to a sales representative, order samples, or receive additional information about AURYXIA. Read Important Safety Information and Prescribing Information on this page."
                />

                <section id="hero" className="flood-ida col-8 col-lg-6">
                    <div className="flex-row">
                        <div>
                            <h1>Stay connected with AURYXIA</h1>
                            <ConnectForm
                                location={location}
                                mode={connectFormModes.ida}
                            />
                        </div>
                    </div>
                </section>

            </IDALayout>
        </>
    )
}

export default IdaSignUpPage
