import * as React from "react";
import { navigate } from "gatsby";
import axios from 'axios'

import Api from "../../../utilities/api";
import NpiLookupModal from "./npi-lookup/npi-lookup";
import errorState_xIcon from "../../../images/icons/form_x_icon.png";

const connectFormModes = {
    ida: 1,
    hp: 2
};


const ConnectForm = ({ location, mode }) => {
    let tabIndex = 1;

    // Validate props and determine section-specific values based on the specified mode
    let apiMethod;
    let sectionClassName;
    switch (mode) {
        case connectFormModes.ida:
            apiMethod = "SignUpSubmitionIDA.php";
            sectionClassName = "ida";
            break;

        case connectFormModes.hp:
            apiMethod = "SignUpSubmition.php";
            sectionClassName = "hpp";
            break;

        default:
            throw new Error(`Invalid mode prop passed to ConnectForm: ${mode}`);
    }

    // Field references
    const fields = {
        // Request type
        requestSignUp: React.createRef(),
        requestRep: React.createRef(),
        requestSamples: React.createRef(),

        // Main form
        npiNumber: React.createRef(),
        noNpi: React.createRef(),
        firstName: React.createRef(),
        lastName: React.createRef(),
        email: React.createRef(),
        zip: React.createRef()
    };

    // Create state objects for storing the state of the queried request types (queryState)
    // as well as the current page request type selection as specified by the user
    // (requestTypeState)
    const [queryState, setQueryState] = React.useState(null);
    const [requestTypeState, setRequestTypeState] = React.useState(
        {
            // Checkbox states
            signUp: false,
            rep: false,
            samples: false,

            // When none of the request type checkboxes have been checked, the form requires
            // the user to click a "next" button to proceed; otherwise, the form updates in
            // realtime when the checkbox states are changed
            waitingConfirmation: true
        }
    );

    // Monitor for changes to location.search, setting our current query & page request type
    // states when necessary
    React.useEffect(
        () => {
            // Load any values specified in the query string
            const querySignUp =
                /[?&]request-signup=true/.test(location.search);

            const queryRep =
                /[?&]request-rep=true/.test(location.search);

            const querySamples =
                /[?&]request-samples=true/.test(location.search);

            // Determine if our queryState object has been established yet
            let setRequestStateFromQuery = false;
            if (queryState === null) {
                // Our queryState object isn't established yet - set it now
                setRequestStateFromQuery = true;
            } else {
                // Our queryState object has already been established - check
                // if the query has changed compared to what we recorded previously
                if (querySignUp !== queryState.signUp ||
                    queryRep !== queryState.rep ||
                    querySamples !== queryState.samples) {
                    // The query has changed - record its new state and reset our
                    // requestTypeState
                    setRequestStateFromQuery = true;
                }
            }

            if (setRequestStateFromQuery) {
                // We've decided reset requestTypeState based on the current query
                setQueryState({
                    signUp: querySignUp,
                    rep: queryRep,
                    samples: querySamples
                });

                clearFieldErrors();
                setRequestTypeState({
                    signUp: querySignUp,
                    rep: queryRep,
                    samples: querySamples,
                    waitingConfirmation: !querySignUp && !queryRep && !querySamples
                });
            }
        },
        [
            location.search,
            queryState
        ]
    );

    // Create a state object for storing whether the "no npi" checkbox is checked
    const [noNpiSelected, setNoNpiSelected] = React.useState(false);

    // Create a state object for storing the validation errors associated with our form fields
    const [validationState, setValidationState] = React.useState({
        // Initially empty; this is populated dynamically based on the IDs of the inputs
    });

    // Error handling
    const showFieldErrors = (errors, clearExistingErrors, scrollToTopmostError = false) => {
        const newValidationState = clearExistingErrors === true
            ? {}
            : { ...validationState };

        errors.forEach(error => {
            newValidationState[error.id] = error.e;
        });

        setValidationState(newValidationState);
    };

    const clearFieldErrors = () => {
        setValidationState({
            // Empty indicates no validation errors
        });
    };

    const clearFieldError = (element) => {
        if (validationState[element.id]) {
            const newValidationState = { ...validationState };
            delete newValidationState[element.id];

            setValidationState(newValidationState);
        }
    };

    // Event handlers
    const handleRequestTypeCheckboxChanges = () => {
        let changeOccurred = false;
        let newRequestTypeState = { ...requestTypeState };

        // Check if the samples checkbox state changed
        if (fields.requestSamples.current.checked !== requestTypeState.samples) {
            newRequestTypeState = { ...newRequestTypeState, samples: fields.requestSamples.current.checked };
            changeOccurred = true;
        }

        // Check if the rep checkbox state changed
        if (fields.requestRep.current.checked !== requestTypeState.rep) {
            newRequestTypeState = { ...newRequestTypeState, rep: fields.requestRep.current.checked };
            changeOccurred = true;
        }

        // Check if the signup checkbox state changed
        // if (fields.requestSignUp.current.checked !== requestTypeState.signUp) {
        //     newRequestTypeState = { ...newRequestTypeState, signUp: fields.requestSignUp.current.checked };
        //     changeOccurred = true;
        // }

        // Continue based on whether changes occurred in the above checkbox states
        if (changeOccurred) {
            // Check if all checkboxes are unchecked
            if (newRequestTypeState.signUp !== true &&
                newRequestTypeState.rep !== true &&
                newRequestTypeState.samples !== true) {
                // All the checkboxes are unchecked - start waiting for the "next" button to be clicked
                newRequestTypeState = { ...newRequestTypeState, waitingConfirmation: true };

                // Also clear any validation errors so that they won't be present when the form
                // reappears (the fields will be emptied)
                clearFieldErrors();
            }

            // Update our state object to reflect all the changes
            setRequestTypeState(newRequestTypeState);
        }
    };

    const handleNextButtonClick = event => {
        event.preventDefault();

        // Stop if none of the checkboxes have been selected
        if (!(requestTypeState.signUp || requestTypeState.rep || requestTypeState.samples))
            return;

        // Stop if we're not waiting for the user to click the next button
        if (!requestTypeState.waitingConfirmation)
            return;

        // Update the waiting confirmation flag so that the form will display
        setRequestTypeState({ ...requestTypeState, waitingConfirmation: false });
    };

    const handleNpiLookupClick = event => {
        event.preventDefault();

        // Show the NPI lookup modal
        NpiLookupModal.show(
            {
                npi: fields.npiNumber.current,
                firstName: fields.firstName.current,
                lastName: fields.lastName.current
            },
            event
        );
    };

    const handleNoNpiStateChange = event => {
        // Update state
        setNoNpiSelected(event.target.checked);
    };







    const validateFirstName = event => {
        checkFirstName(removeErrorStates, addErrorStates, event)
    }

    const validateLastName = event => {
        // console.log(event.target)
        checkLastName(removeErrorStates, addErrorStates, event)
    }

    const validateEmail = event => {
        checkEmail(removeErrorStates, addErrorStates, event)
    }

    const validateZip = event => {
        checkZip(removeErrorStates, addErrorStates, event)
    }


    function checkFirstName(removeStates, addStates, event) {
        let fNameValue = fields.firstName.current.value;

        if (fNameValue.length > 0) {
            removeStates(event);
        } else {
            addStates(event);
        }
    }

    function checkLastName(removeStates, addStates, event) {
        let lNameValue = fields.lastName.current.value;

        if (lNameValue.length > 0) {
            removeStates(event);
        } else {
            addStates(event);
        }
    }


    function checkEmail(removeStates, addStates, event) {
        let emailValue = fields.email.current.value;
        if (emailValue.match(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/)) {
            removeStates(event);
        } else {
            addStates(event);
        }
    }

    function checkZip(removeStates, addStates, event) {
        let zipValue = fields.zip.current.value;

        if (zipValue.length > 4) {
            axios.get('https://api.zippopotam.us/us/' + zipValue)
                .then(response => {
                    // console.log(response.status);
                    if (response.status == "200") {
                        // console.log(zipValue + " is a valid zip");
                        removeStates(event);
                    } else {
                        // console.log(zipValue + " is not a valid zip");
                        addStates(event);
                    }
                })
                .catch(error => {
                    // console.log(error);
                    // console.log(zipValue + " is not a valid zip");
                    addStates(event);
                })
        } else {
            // console.log("zip no good");
            addStates(event);

        }
    }









    function addErrorStates(x) {
        x.target.parentNode.parentNode.classList.remove("valid");
        x.target.parentNode.parentNode.classList.add("un-valid");
        x.target.parentNode.parentNode.classList.add("error_state");
    }

    function removeErrorStates(x) {
        x.target.parentNode.parentNode.classList.remove("un-valid");
        x.target.parentNode.parentNode.classList.remove("error_state");
        x.target.parentNode.parentNode.classList.add("valid");
    }



    const validateField = event => {
        // Collect the ID and value of the field we're validating
        const data = {
            id: event.target.id,
            value: event.target.value.trim()
        };


        // Append this for validation that occurs when samples are requested -
        // the API currently uses this to return a different error message
        // for first & last name fields, and could possibly leverage it for
        // other purposes in the future
        if (requestTypeState.samples)
            data.isSampleRequest = true;

        // Append an associated data point if necessary
        if (!fields.noNpi.current.checked) {
            // The "I don't have an NPI number" checkbox isn't checked
            if (data.id === fields.npiNumber.current.id) {
                // We're validating the NPI number - append the last name
                data.associatedValue = fields.lastName.current.value.trim();
            } else if (data.id === fields.lastName.current.id) {
                // We're validating the last name - append the NPI number
                data.associatedValue = fields.npiNumber.current.value.trim();
            }
        }

        // Call the API
        Api.callHcp(
            "Validate",
            data,
            function (response) {
                if (response.errors) {
                    // Show field error
                    showFieldErrors(response.errors, false);
                    return;
                }

                // Clear field error, if necessary
                clearFieldError(event.target);
            }
        );
    };

    const checkAllErrorStates = event => {

        // console.log(fields);

        if ((fields.firstName.current.value.length > 0) && (fields.lastName.current.value.length > 0) && (fields.email.current.value.length > 0)) {

            if (fields.firstName.current.parentNode.parentNode.classList.contains("un-valid")) {
                checkFirstName(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.firstName)
            } else {
                removeErrorStatesOnSubmit(fields.firstName);

            }

            if (fields.lastName.current.parentNode.parentNode.classList.contains("un-valid")) {
                checkLastName(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.lastName)

            } else {
                removeErrorStatesOnSubmit(fields.lastName);

            }

            if (fields.email.current.parentNode.parentNode.classList.contains("un-valid")) {
                checkEmail(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.email)

            } else {
                removeErrorStatesOnSubmit(fields.email);

            }


        }


        if (!requestTypeState.signUp) {
            if ((fields.zip.current.value.length > 0)) {
                if (fields.zip.current.parentNode.parentNode.classList.contains("un-valid")) {
                    checkZip(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.zip)

                } else {
                    removeErrorStatesOnSubmit(fields.zip);

                }
            }
        }

    }

    function addErrorStatesOnSubmit(x) {
        x.current.parentNode.parentNode.classList.remove("valid");
        x.current.parentNode.parentNode.classList.add("un-valid");
        x.current.parentNode.parentNode.classList.add("error_state");
    }

    function removeErrorStatesOnSubmit(x) {
        x.current.parentNode.parentNode.classList.remove("un-valid");
        x.current.parentNode.parentNode.classList.remove("error_state");
        x.current.parentNode.parentNode.classList.add("valid");
    }


    const handleFormSubmit = event => {
        event.preventDefault();


        if (fields.firstName.current.parentNode.parentNode.classList.contains("un-valid")) {
            checkFirstName(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.firstName);
        } else {
            removeErrorStatesOnSubmit(fields.firstName);
        }

        if (fields.lastName.current.parentNode.parentNode.classList.contains("un-valid")) {
            checkLastName(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.lastName);
        } else {
            removeErrorStatesOnSubmit(fields.lastName);
        }

        if (fields.email.current.parentNode.parentNode.classList.contains("un-valid")) {
            checkEmail(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.email)
        } else {
            removeErrorStatesOnSubmit(fields.email);
        }

        if (!requestTypeState.signUp) {
            if (fields.zip.current.parentNode.parentNode.classList.contains("un-valid")) {
                checkZip(removeErrorStatesOnSubmit, addErrorStatesOnSubmit, fields.zip)
            } else {
                removeErrorStatesOnSubmit(fields.zip);
            }
        }






        // Stop if none of the checkboxes have been selected
        if (!(requestTypeState.signUp || requestTypeState.rep || requestTypeState.samples))
            return;

        // Stop if we're waiting for the user to click the next button
        if (requestTypeState.waitingConfirmation)
            return;

        // Collect the data to send to the API
        const data = {
            form: event.target.classList[0],
            signUp: requestTypeState.signUp,
            requestRep: requestTypeState.rep,
            requestSamples: requestTypeState.samples,
            // noNpi: fields.noNpi.current.checked,
            firstName: fields.firstName.current.value.trim(),
            lastName: fields.lastName.current.value.trim(),
            email: fields.email.current.value.trim()
        };

        if (!data.noNpi)
            data.npi = fields.npiNumber.current.value.trim();

        if (requestTypeState.samples || requestTypeState.rep)
            data.zip = fields.zip.current.value.trim();




        if (!requestTypeState.signUp) {
            if ((fields.firstName.current.parentNode.parentNode.classList.contains("valid")) && (fields.lastName.current.parentNode.parentNode.classList.contains("valid")) && (fields.email.current.parentNode.parentNode.classList.contains("valid")) && (fields.zip.current.parentNode.parentNode.classList.contains("valid"))) {
                submitForm();

            }
        } else {
            if ((fields.firstName.current.parentNode.parentNode.classList.contains("valid")) && (fields.lastName.current.parentNode.parentNode.classList.contains("valid")) && (fields.email.current.parentNode.parentNode.classList.contains("valid"))) {
                submitForm();

            }
        }

        function submitForm() {
            console.log(data);
            // navigate("../thank-you");


            //Call the API
            Api.callHcp(
                apiMethod,
                data,
                function (response) {
                    if (response.errors) {
                        // Show errors
                        showFieldErrors(response.errors, true, true);
                        return;
                    }

                    let gtmEventCallback = function () {
                        // Proceed to the thank you page
                        navigate("../thank-you");
                    };

                    /* const gtmData = {
                        event: "data-completion",
                        "data-completion": "completed",
                        "signUpId": response.signUpId,
                        "repRequestId": response.repRequestId,
                        "sampleRequestId": response.sampleRequestId,
                        "eventCallback": gtmEventCallback
                    };

                    if (response.signUpId)
                        gtmData["signUpId"] = response.signUpId;
                    if (response.repRequestId)
                        gtmData["repRequestId"] = response.repRequestId;
                    if (response.sampleRequestId)
                        gtmData["sampleRequestId"] = response.sampleRequestId;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(gtmData); 

                    // If GTM is slow to respond for whatever reason (or it's disabled) 
                    // just redirect after a moment anyhow
                    setTimeout(gtmEventCallback, 2000);*/
                }
            );


        }







    };

    // Setup form content - we do it this way (in part) so as to dynamically apply the appropriate
    // tabindex values
    const createErrorFormFieldClassName = (fieldId) => {
        const message = validationState[fieldId];
        if (message)
            return "form-field error";
        else
            return "form-field";
    };

    const createErrorMessage = (fieldId) => {
        const message = validationState[fieldId];
        if (message)
            return <div className="error">{message}</div>;
        else
            return null;
    };

    // The introduction & request type fields are always displayed
    const requestTypeCheckboxes =
        <>
            <div className="checkbox-field">
                <div>
                    <label>
                        Request samples

                        <input
                            ref={fields.requestSamples}
                            id="request-type-samples"
                            type="checkbox"
                            tabIndex={tabIndex++}
                            checked={requestTypeState.samples}
                            onChange={handleRequestTypeCheckboxChanges} />
                    </label>
                </div>
            </div>
            <div className="checkbox-field">
                <div>
                    <label>
                        Connect with a sales representative

                        <input
                            ref={fields.requestRep}
                            id="request-type-rep"
                            type="checkbox"
                            tabIndex={tabIndex++}
                            checked={requestTypeState.rep}
                            onChange={handleRequestTypeCheckboxChanges} />
                    </label>
                </div>
            </div>

        </>;

    // The remaining form fields are dynamic based on the type of request selected
    // by the user
    let requestTypeNextButton = null;
    let formFields = null;
    if (requestTypeState.waitingConfirmation) {
        // The user has yet to specify which type of request they would like to make -
        // show the "next" button
        requestTypeNextButton =
            <button
                id="request-type-next-button"
                className={`cta ${sectionClassName}`}
                disabled={!(requestTypeState.signUp || requestTypeState.rep || requestTypeState.samples)}
                tabIndex={tabIndex++}
                onClick={handleNextButtonClick}
            >
                <span>Next</span>
            </button>;
    } else {
        // The user has specified the type of request they would like to make -
        // show the appropriate form fields

        const npiFieldset =

            <fieldset className="npi-information">

                <p className="requiredFieldsTxt">Fields marked with an asterisk (*) are required.</p>



                <div className={noNpiSelected ? "form-field disabled" : createErrorFormFieldClassName("npi")}>
                    <label htmlFor="npi">NPI NUMBER (OPTIONAL)</label>
                    <div>
                        <input
                            ref={fields.npiNumber}
                            id="npi"
                            type="text"
                            maxLength="10"
                            tabIndex={tabIndex++}
                            //disabled={noNpiSelected}
                            // onBlur={validateField}
                            // onBlur={validateInputFields}

                            // Only allow numeric characters
                            onInput={event => event.target.value = event.target.value.replace(/\D/g, "")} />
                    </div>
                </div>


            </fieldset>;

        let nameFields = <>
            <div className="form-field un-valid">
                <label htmlFor="first-name">{requestTypeState.samples === true ? "PRESCRIBER FIRST NAME" : "PRESCRIBER FIRST NAME"}<span className="required">*</span></label>
                <div className="input-wrapper">
                    <input
                        ref={fields.firstName}
                        id="first-name"
                        type="text"
                        maxLength="100"
                        tabIndex={tabIndex++}
                        // onBlur={validateField} 
                        onBlur={validateFirstName}
                    />


                    <div className="error_message error_message_icon">
                        <img src={errorState_xIcon} alt="" />
                    </div>


                </div>




                <div className="error_message ">
                    <img className="desktopErrorIcon" src={errorState_xIcon} alt="" /> Enter the prescriber's first name.
                </div>
            </div>


            <div className="form-field un-valid">
                <label htmlFor="last-name">{requestTypeState.samples === true ? "PRESCRIBER LAST NAME" : "PRESCRIBER LAST NAME"}<span className="required">*</span></label>
                <div className="input-wrapper">
                    <input
                        ref={fields.lastName}
                        id="last-name"
                        type="text"
                        maxLength="100"
                        tabIndex={tabIndex++}
                        //onBlur={validateField}
                        onBlur={validateLastName}
                    />


                    <div className="error_message error_message_icon">
                        <img src={errorState_xIcon} alt="" />
                    </div>

                </div>

                <div className="error_message">
                    <img className="desktopErrorIcon" src={errorState_xIcon} alt="" /> Enter the prescriber's last name.
                </div>
            </div>
        </>;

        let emailAddressFields =
            <div className="form-field un-valid">
                <label htmlFor="email">EMAIL ADDRESS<span className="required">*</span></label>
                <div className="input-wrapper">
                    <input
                        ref={fields.email}
                        id="email"
                        type="text"
                        maxLength="200"
                        tabIndex={tabIndex++}
                        // onBlur={validateField} 
                        onBlur={validateEmail}

                    />


                    <div className="error_message error_message_icon">
                        <img src={errorState_xIcon} alt="" />
                    </div>
                </div>

                <div className="error_message">
                    <img className="desktopErrorIcon" src={errorState_xIcon} alt="" /> Enter a valid email address.
                </div>

            </div>;

        let zipCodeFields = null;
        if (requestTypeState.samples || requestTypeState.rep) {
            zipCodeFields =
                <div className="zipFormField form-field un-valid">
                    <label htmlFor="zip">ZIP CODE<span className="required">*</span></label>
                    <div className="zipDiv input-wrapper">
                        <input
                            ref={fields.zip}
                            id="zip"
                            type="text"
                            maxLength="5"
                            tabIndex={tabIndex++}
                            //onBlur={validateField}
                            //onBlur={validateZip}
                            onBlur={validateZip}


                            // Only allow numeric characters
                            onInput={event => event.target.value = event.target.value.replace(/\D/g, "")} />

                        <div className="error_message error_message_icon">
                            <img src={errorState_xIcon} alt="" />
                        </div>

                    </div>

                    <div className="error_message">
                        <img className="desktopErrorIcon" src={errorState_xIcon} alt="" /> Enter a valid ZIP code.
                </div>                </div>;
        }

        let samplesOrRepInstructions = null;
        if (requestTypeState.samples || requestTypeState.rep)
            samplesOrRepInstructions = <p>By providing my information above, I agree to the Akebia Therapeutics, Inc. (&#x201c;Akebia&#x201d;) <a href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and give Akebia Sales Representatives permission to contact me via email for the purposes of obtaining samples.</p>;

        let signUpInstructions = null;
        if (requestTypeState.signUp)
            signUpInstructions = <p>By providing my information above, I agree to the Akebia Therapeutics, Inc. (&#x201c;Akebia&#x201d;) <a href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and give Akebia, its affiliates, and business partners permission to contact me via email for marketing purposes or otherwise provide me with information about company products, services, and programs or other topics of interest, conduct market research, or otherwise ask me about my experience with or thoughts about such topics. Akebia will not sell or transfer my information to any unrelated third party for marketing purposes without my express permission. I may opt out at any time by clicking the unsubscribe link within any email I receive.</p>;

        formFields = <>
            {/* NPI number */}
            {npiFieldset}

            {/* User information */}
            <fieldset className="user-information">
                {nameFields}
                {emailAddressFields}
                {zipCodeFields}
            </fieldset>

            {/* Submit button */}
            <fieldset className="submit-controls">
                <div className="form-field">
                    <input
                        id="sign-up-button"
                        className={`cta ${sectionClassName}`}
                        type="submit"
                        value="Submit"
                        // onMouseOver={checkAllErrorStates}
                        tabIndex={tabIndex++} />
                </div>

                <div className="instructions">
                    {samplesOrRepInstructions}
                    {signUpInstructions}
                </div>
            </fieldset>
        </>;
    }

    return (
        // <section className="col-8 col-lg-6 center">
        <form
            id="connect-form"
            className={sectionClassName}
            onSubmit={handleFormSubmit}
            onMouseOver={checkAllErrorStates}
        >
            <div className="flex-row">
                {/* Introduction & request type subform */}
                <fieldset className="request-type">
                    <h2>Tell us what you&#x2019;d like to do, and we&#x2019;ll be sure you are in the know about AURYXIA.<br /><strong>Select all that apply.</strong></h2>

                    {requestTypeCheckboxes}
                    {requestTypeNextButton}
                </fieldset>
                {/* Main form fields, if a form is currently displayed */}

                {formFields}
            </div>
        </form>
        // </section>
    );
};

// export default ConnectForm;
export { ConnectForm as default, connectFormModes };
